<template>
  <MKBTable show-result elevation="0" />
</template>

<script>
import MKBTable from '../entity/mkb10/MKBTable.vue'

export default {
  name: 'ReferencesMKB10',
  components: { MKBTable },
}
</script>
